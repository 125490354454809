body
{
    font-family: Arial, Helvetica, sans-serif;
}
.accordion
{
    width:100%;
    min-height:300px;
    margin:20px auto;
}
.accordion-item {
    font-size: 1em;
    margin: 0 10px 0 10px;
    padding: 10px;
    height: 20px;
    background: #f2f2f2;
    border-bottom:1px solid #ccc;
    color: #000;
    cursor:pointer;
}

.accordion-item.open
{
    background:#14ad40;
    border-bottom:0px;
    color:#fff;
}
.accordion-item.open .type {
    float: right;
    background: url('http://vivekarora.com/images/minus.png') center no-repeat;
    padding: 10px;
}

.accordion-item .type {
    float: right;
    background: url('http://vivekarora.com/images/plus.png') center no-repeat;
    padding: 10px;
}

div.data {
    background: #fff;
    margin: 0 10px 0 10px;
    padding: 10px;
    border:1px solid #ccc;
    font-size: .8em;
    line-height: 140%;
    display:none;
}